<form [formGroup]="form" novalidate (submit)="submit()">

    <div class="tw-bg-orange-200 tw-mb-4 tw-p-4 tw-rounded-md">
        <p>
            Confirm with your internal transaction ID number that you have transferred
            <strong>{{config.currency}} {{config.amount}},-</strong>
            {{config.receiver?.name ? 'to ' + config.receiver.name : ''}}
        </p>
    </div>

    <div class="layout-column">

        <mat-form-field >
            <mat-label>transactionId</mat-label>
            <input type="text" matInput formControlName="paymentDetails" />
            <mat-error>Required</mat-error>
        </mat-form-field>
        
    </div>

    <div class="tw-flex tw-flex-row tw-justify-between">

        <button type="button" mat-button mat-dialog-close="false">
            Cancel
        </button>

        <button type="submit" mat-button class="" [disabled]="isSubmitting">
            {{ provider.direction === 1 ? 'Request' : 'Send'}}
        </button>

    </div>

</form>

import { Component, Input } from '@angular/core'
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PaymentComponentConfig } from './payment';

@Component({
    selector: 'payment-bank',
    templateUrl: './bank.html',
    standalone: false
})
export class BankPaymentComponent
{
    public form: FormGroup;
    @Input()
    config: PaymentComponentConfig;

    constructor()
    {
    }

    public ngOnInit()
    {
        this.form = new FormGroup({
            paymentDetails: new FormControl(undefined, [Validators.required]),
            amount: new FormControl(this.config.amount),
            currency: new FormControl(this.config.currency)
        });
    }

    public async submit()
    {

    }
}
import { Component, Input } from '@angular/core';

@Component({
    selector: 'ddf-state',
    templateUrl: 'ddf-state.component.html',
    standalone: false
})
export class StateComponent
{
    @Input() isLoading = false;
    @Input() hasData = false;
    @Input() stateMessage = 'No data available.';

    public get showEmptyState(): boolean 
    {
        return !this.isLoading && !this.hasData;
    }
}

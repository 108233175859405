import { Injectable } from '@angular/core';
import { CommandService, ICommandResponse } from '@core/services/command.service';
import { QueryService, IQueryResponse } from '@core/services/query.service';


export interface PendingCountQuery
{
    pendingCount: number;
}

export interface OrderQuery
{
    order: OrderModel;
    orderLines: OrderLineModel[];
    orderInstalments: OrderInstalmentModel[];
}

export interface Policy
{
    id: string;
    name: string;
}

export interface OrderLineModel
{
    id: string;
    orderType: number;
    name: string;
    price: number;
    paidAt: Date;
    createdAt: Date;
    customerId: string;
    customerName: string;
    customerNationalId: string;
    customerPhone: string;
    collectiveId: string;
    collectiveName: string;
    organizationId: string;
    organizationName: string;
    currency: string;
    policies: Policy[];
}

export interface OrderInstalmentModel
{
    id: string;
    amount: number;
    createdAt: Date;
}


export interface OrderModel
{
    id: string;
    createdAt: Date;
    name: string;
    totalAmount: number;
    status: number;
    customerId: string;
    customerName: string;
    customerNationalId: string;
    collectiveName: string;
    currency: string;
}

export interface ProductModel
{
    id: string;
    name: string;
}

export interface OrdersQueryPayload
{
    searchString?: string;
    sortBy?: string;
    orderBy?: string;
    status?: boolean;
    skip?: number;
    take?: number;
}

export interface OrdersQueryResponse
{
    orders: OrderModel[];
    totalCount: number;
}

@Injectable({ providedIn: 'root' })
export class OrderRepository
{

    constructor(
        private commandService: CommandService,
        private queryService: QueryService)
    {
    }

    public getOrders(data: OrdersQueryPayload): Promise<IQueryResponse<OrdersQueryResponse>>
    {
        return this.queryService.queryAsync('OrdersQuery', data);
    }

    public getOrder(orderId: string): Promise<IQueryResponse<OrderQuery>>
    {
        return this.queryService.queryAsync('OrderQuery', { orderId });
    }

    public initiate(orderId: string): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('OrderInit', { orderId });
    }

    public submit(data: { userId: string; productId: string; properties: {}; orderId?: string; }): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('OrderSubmit', data);
    }

    public create(data: any): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('OrderCreate', data);
    }

    public simulatePayment(data: any): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('ReceivePayment', data);
    }

    public bankPaymentAsync(data: {}): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('BankPayment', data);
    }

    public resendPaymentAsync(data: {}): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('ResendPayment', data);
    }

    public cancelOrder(orderId: string): Promise<ICommandResponse>
    {
        const data = {
            Id: orderId
        };
        return this.commandService.publishAsync('OrderCancelOrderById', data);
    }
}

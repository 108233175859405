<form [formGroup]="form" (submit)="submit($event)" >

    <div class="layout-column">

        <mat-form-field  class="flex" *ngIf="!(this.parent.isForJunior && countryCode==='BI')">
            <mat-label>{{parent.isForJunior ? ('birthCertificateNumber' )
                : parent.isForSpouse ? ('spouseNationalId' ) : ('nationalId' )}}</mat-label>
            <input type="text" matInput formControlName="idNumber" />           
            <mat-error>required</mat-error>
        </mat-form-field>

        <mat-form-field  class="flex">
            <mat-label>firstName</mat-label>
            <input matInput formControlName="firstName"/>
            <mat-error>firstName isRequired</mat-error>
        </mat-form-field>

        <mat-form-field  class="flex">
            <mat-label>lastName</mat-label>
            <input matInput formControlName="familyName"/>
            <mat-error>lastName isRequired</mat-error>
        </mat-form-field>

        <mat-form-field >
            <mat-label>dateOfBirth</mat-label>
            <input formControlName="dateOfBirth" matInput readonly (focus)="picker.open()" [max]="maxDate" [min]="minDate" [matDatepicker]="picker" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker startView="multi-year" #picker></mat-datepicker>
            <mat-error>dateOfBirth isRequired</mat-error>
        </mat-form-field>
        
        <mat-form-field >
            <mat-label>select gender</mat-label>
            <mat-select formControlName="gender">
              <mat-option value="1">male</mat-option>
              <mat-option value="2">female</mat-option> 
            </mat-select> 
          </mat-form-field> 

        <mat-form-field  class="flex" *ngIf="parent.isForSpouse">
            <mat-label>phoneNumber</mat-label>
            <input type="tel" placeholder="" matInput formControlName="phone">
        </mat-form-field>


         <div class="message is-error mb-15" *ngIf="message">{{message}}</div>

    </div>

<div class="tw-flex tw-flex-row tw-justify-between">

   <button type="button" mat-button class="btn" (click)="previous()" [disabled]="ignorePrevious">
    previous
    </button>

    <button type="submit" mat-button class="btn" [disabled]="clickedNext">
        next
    </button> 
</div>

</form>
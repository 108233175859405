import { Component, Input } from '@angular/core';

@Component({
    selector: 'ddf-loader',
    template: `
            <section class="pv-20 m-8">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                <h3 *ngIf="message" style="text-align: center;margin-top: 15px">{{message}}</h3>
            </section>
    `,
    standalone: false
})
export class LoaderComponent
{
    @Input() public message: string | undefined;
}

import { Pipe, PipeTransform } from '@angular/core';
import { ClaimStatus } from '@shared/repositories/claim.repository';


@Pipe({
    name: 'claimStatus',
    standalone: false
})
export class ClaimStatusPipe implements PipeTransform {

  transform(value: ClaimStatus): string {
    const statusName = ClaimStatus[value]; 
    return statusName || 'Unknown'; 
  }
}

<header *ngIf="title && (actionLinks$ | async); let actionLinks">

    <div class="ph-10">

        <div class="layout-row layout-align-space-between-center">


            <a *ngIf="returnUrl" mat-icon-button (click)="onBackClick($event)">
                <mat-icon svgIcon="chevron-left"></mat-icon>
            </a>

            <h2 class="flex tw-uppercase">
                <small style="display: block;font-size: .8rem;" *ngIf="pretitle">{{pretitle}}</small>
                {{title || ''}}
                <small style="display: block;font-size: .8rem;" *ngIf="posttitle">{{posttitle}}</small>
            </h2>

            <div class="hide-xs tw-mr-4">
                <button type="button" (click)="onClick(link)" *ngFor="let link of actionLinks" matTooltip="{{link.label }}" mat-icon-button [attr.data-test]="link.label">
                    <mat-icon [svgIcon]="link.icon"></mat-icon>
                </button>
            </div>

            <div class="hide-gt-xs" *ngIf="actionLinks && actionLinks.length > 0">
                <button type="button" mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon svgIcon="dots-vertical"></mat-icon>
                </button>

                <mat-menu #menu>
                    <button type="button" mat-menu-item (click)="onClick(link)" *ngFor="let link of actionLinks">
                        <mat-icon [svgIcon]="link.icon"></mat-icon>
                        <span>{{link.label }}</span>
                    </button>
                </mat-menu>

            </div>

        </div>

    </div>

</header>

<div class="navbar" *ngIf="tabs.length > 0">

    <nav mat-tab-nav-bar [tabPanel]="tabPanel">
        @for (tab of tabs; track tab) {
          <a mat-tab-link
          [routerLink]="tab.path"
          queryParamsHandling="preserve"
          routerLinkActive #rla="routerLinkActive"
          [active]="rla.isActive"> {{tab.label}} </a>
        }
      </nav>
      <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>

</div>



<div class="tw-m-4">
    <ng-content></ng-content>
</div>

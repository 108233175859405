import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

export interface IFile 
{
    ownerId: string;
    fileType: string;
}

@Injectable({
    providedIn: 'root'
})
export class FileListingService
{

    files_url: string;
    rename_url: string;
    delete_url: string;

    constructor(
        private httpClient: HttpClient)
    {
        this.files_url = `${environment.filesUrl}/upload/files`;
        this.rename_url = `${environment.filesUrl}/upload/edit/desc`;
        this.delete_url = `${environment.filesUrl}/upload//delete`;
    }

    public getFiles(data: IFile)
    {
        return this.httpClient.post<any>(this.files_url, data);
    }

    public renameFile(file)
    {
        this.httpClient.post<any>(this.rename_url,
            {
                FileName: file.FileName,
                desc: file.Description
            }).subscribe(
                res =>
                {
                    // POST response
                },
                err =>
                {
                    throw new Error('POST: Rename error: ' + err);
                });
    }

    public deleteFile(file)
    {
        this.httpClient.post<any>(this.delete_url, { FileName: file.FileName })
            .subscribe(
                res =>
                {
                    // POST response
                },
                err =>
                {
                    throw new Error('POST: Delete error: ' + err);
                });
    }

}

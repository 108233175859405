import { Component, Input } from '@angular/core';
import { BaseComponent } from '@core/abstracts/base.component';

@Component({
    selector: 'skeleton',
    templateUrl: './skeleton.component.html',
    standalone: false
})
export class SkeletonComponent extends BaseComponent
{

    @Input() 
    public items: number = 10;
    
    constructor()
    {
        super();
    }
}
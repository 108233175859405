import { CommandService, ICommandResponse } from '@/core/services/command.service';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class DocumentRepository
{
    constructor(private commandService: CommandService)
    {
    }

    public submit(data: any): Observable<ICommandResponse>
    {
        const commandName = data.documentId
            ? 'DocumentUpdate'
            : 'DocumentCreate';

        return from(this.commandService.publishAsync(commandName, data));
    }
}


<div class="tw-flex tw-flex-col tw-gap-2 tw-text-center tw-font-bold tw-text-lg" *ngIf="!selected">

    <ng-container *ngIf="isLoaded; else loading">

        <ng-container *ngIf="providers.length > 0; else noProviders">
    
            <div class="tw-py-6 tw-px-2 tw-bg-blue-200 tw-rounded-md tw-cursor-pointer" *ngFor="let pp of providers" (click)="select(pp)">
                {{ pp.name }}
            </div>
    
        </ng-container>
    
        <ng-template #noProviders>
            <p>No {{ direction === 1 ? 'payment' : 'payout'}} providers found</p>
        </ng-template>

    </ng-container>

    <ng-template #loading>
        <p>Loading..</p>
    </ng-template>

    <div class="tw-pt-4">
        <button type="button" mat-button mat-dialog-close class="btn">
            Close
        </button>
    </div>

</div>

<div *ngIf="selected">
    <payment-mpesa *ngIf="selected.discriminator === 'mpesa'" [config]="config"></payment-mpesa>
    <payment-mpesa-instalment *ngIf="selected.discriminator === 'mpesa_instalment'" [config]="config"></payment-mpesa-instalment>
    <payment-bank *ngIf="selected.discriminator === 'bank'" [config]="config"></payment-bank>
    <payment-manual *ngIf="selected.discriminator === 'manual'" [config]="config"></payment-manual>
</div>
import { Injectable } from '@angular/core';
import { CommandService, ICommandResponse } from '@core/services/command.service';
import { QueryService, IQueryResponse } from '@core/services/query.service';

export const BurundiCountryId: string = 'c972417a-8585-4ab2-8184-57e190043e83';
export const KenyaCountryId: string = '2bd38ce9-5541-4d12-b5c5-92f491da8b96';

export interface CountryModel
{
    id: string;
    name: string;
}

export interface GeolocationModel
{
    id: string;
    name: string;
}

export interface GeolocationQuery
{
    countryId?: string;
    type?: string;
    searchString?: string;
}


@Injectable({ providedIn: 'root' })
export class GeolocationRepository
{
    constructor(private queryService: QueryService)
    {
    }

    public async getCountries(countryId?: string): Promise<IQueryResponse<CountryModel[]>>
    {
        return await this.queryService.queryAsync<CountryModel[]>('CountriesQuery', { countryId });
    }

    public async getCountry(geolocationId: string): Promise<IQueryResponse<CountryModel[]>>
    {
        return await this.queryService.queryAsync<CountryModel[]>('CountryQuery', { geolocationId });
    }

    public async getGeolocations(data: GeolocationQuery): Promise<IQueryResponse<GeolocationModel[]>>
    {
        return await this.queryService.queryAsync<GeolocationModel[]>('GeolocationQuery', data);
    }
}

import { Injectable } from '@angular/core';
import { CommandService, ICommandResponse } from '@core/services/command.service';
import { QueryService, IQueryResponse } from '@core/services/query.service';

export interface Underwriter
{
    id: string;
    name: string;
    email: string;
    phoneNumber: string;
    address: string;
    underwriterPercentage: number;
    ipaasPercentage: number;
    distributionPercentage: number;
    createdAt: Date;
    updatedAt: Date;
}

export interface Metrics
{
    policies: number;
    claims: number;
    currency: string;
    paidClaimsAmount: number;
    paidClaims: number;
    claimFrequency: number;
    claimTurnAroundTime: number;
}

export interface Manager
{
    id: string;
    name: string;
    nationalId: string;
    phoneNumber: string;
    memberSince: Date;
    cancelledAt?: any;
}

export interface Product
{
    id: string;
    createdAt: Date;
    name: string;
    productType: string;
    price: number;
    currency: string;
    underwriterId: string;
    underwriter: string;
    level: number;
    summary: {
        excluded: string[];
        included: string[];
    };
    description: string;
    canDefault: boolean;
    canExtend: boolean;
    properties: {
        isBeneficiaryRequired: boolean;
        maxCombinedLifeClaims: number;
    };
    tags: string[];
    isFamily: boolean;
}

export interface UnderwriterManagerRequest
{
    underwriterId: string;
    userId: string;
}

export interface UnderwritersQueryResponse
{
    underwriters: Underwriter[];
    totalCount: number;
}

@Injectable({ providedIn: 'root' })
export class UnderwriterRepository
{
    constructor(
        private commandService: CommandService,
        private queryService: QueryService,
    ) { }

    public create(data: Underwriter): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('UnderwriterCreate', data);
    }

    public update(data: Underwriter): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('UnderwriterUpdate', data);
    }

    public addManager(data: UnderwriterManagerRequest): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('UnderwriterSetUser', data);
    }

    public removeManager(data: UnderwriterManagerRequest): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('UnderwriterRemoveUser', data);
    }

    public async getUnderwriters(data: any): Promise<IQueryResponse<UnderwritersQueryResponse>>
    {
        return this.queryService.queryAsync<UnderwritersQueryResponse>('UnderwritersQuery', data);
    }

    public async getUnderwriter(underwriterId: string): Promise<IQueryResponse<Underwriter>>
    {
        return this.queryService.queryAsync<Underwriter>('UnderwriterQuery', { underwriterId });
    }

    public async getUnderwriterManagers(underwriterId: string): Promise<IQueryResponse<Manager[]>>
    {
        return this.queryService.queryAsync<Manager[]>('UnderwriterManagersQuery', { underwriterId });
    }

    public async getUnderwriterMetrics(underwriterId: string): Promise<IQueryResponse<Metrics>>
    {
        return this.queryService.queryAsync<Metrics>('UnderwriterMetricsQuery', { underwriterId });
    }
}

import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Logger } from '@core/helpers/logger';
import { AuthService } from '@core/services/auth.service';
import { TokenService } from '@core/services/token.service';
import { environment } from '@environments/environment';


export interface IQueryResponse<T>
{
    queryId: string;
    result: T;
    executionTime: number;
    isSuccess: boolean;
    message?: string;
}

@Injectable({ providedIn: 'any' })
export class QueryService
{
    constructor(
        private httpClient: HttpClient,
        private tokenService: TokenService,
        private authService: AuthService,
        private router: Router,
        private snackBar: MatSnackBar,
    ) { }

    public async queryAsync<T>(queryName: string, query = {}): Promise<IQueryResponse<T>>
    {
        let response: IQueryResponse<T> | undefined;

        try
        {
            if (!this.tokenService.isAuth)
            {
                await this.authService.refreshAsync();
            }

            const appId: string = environment.appId.toString();
            const headers = new HttpHeaders({
                AppId: appId,
                Query: queryName,
                Authorization: `Bearer ${this.tokenService.token}`
            });

            response = await this.httpClient
                .post<IQueryResponse<T>>(`${environment.queryUrl}`, query, { headers, withCredentials: true })
                .toPromise();

            Logger.log(queryName, query, response);
        } catch (error)
        {
            if ('status' in error && error.status === 401)
            {
                this.snackBar.open('Your session has expired. Please signin again')
                this.router.navigate(['/auth/signin']);
                return;
            }

            this.snackBar.open(error.message || 'An error occured');
            throw error;
        }

        return response;
    }
}

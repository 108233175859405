import { Injectable } from '@angular/core';
import { CommandService, ICommandResponse } from '@core/services/command.service';
import { QueryService, IQueryResponse } from '@core/services/query.service';


export interface MPesaBalanceModel 
{
    lastUpdated: Date;
    balance: number;
}

export interface MpesaBalanceQueryResult
{
    utilityAccount: MPesaBalanceModel;
    workingAccount: MPesaBalanceModel;
}

export interface TransactionQueryPayload
{
    id?: string;
    source?: string;
    destination?: string;
    category?: string;
    startDate?: string;
    endDate?: string;
    skip: number;    
    take: number;
}

export interface TransactionModel
{
    date: string;
    category: string;
    id?: string;
    source: string;
    destination: string;
    currency: number;
    amount: number;
    status: string;
}

export interface TransactionQueryResult
{
    transactions: TransactionModel[];
    totalCount: number;
}

@Injectable({ providedIn: 'root' })
export class TransactionRepository
{
    constructor(private queryService: QueryService) { }

    public async getTransactions(data: TransactionQueryPayload): Promise<IQueryResponse<TransactionQueryResult>>
    {
        return await this.queryService.queryAsync<any>('TransactionQuery', data);
    }

    public async getMpesaBalance(): Promise<MpesaBalanceQueryResult>
    {
        const response = await this.queryService.queryAsync<any>('MPesaBalanceQuery');
        return response.result;
    }

    public async getPayments(data: any): Promise<any>
    {
        const response = await this.queryService.queryAsync('GetPayments', data);
        return response.result;
    }

    public async getPayouts(data: any): Promise<any>
    {
        const response = await this.queryService.queryAsync('GetPayouts', data);
        return response.result;
    }

    public async getSentSms(data: any): Promise<any>
    {
        const response = await this.queryService.queryAsync('GetSmsLog', data);
        return response.result;
    }
}

<form [formGroup]="form" (submit)="submitAsync($event)">

    <div class="layout-column">

        <mat-form-field  class="flex">
            <mat-label>Birth Certificate Number</mat-label>
            <input matInput formControlName="idNumber"/>
            <mat-error>Required</mat-error>
        </mat-form-field>

        <mat-form-field  class="flex" *ngIf="skipIprs">
            <mat-label>Name Of Child</mat-label>
            <input matInput formControlName="name"/>
            <mat-error>Required</mat-error>
        </mat-form-field>

        <mat-form-field  *ngIf="skipIprs">
            <mat-label>Date of Birth</mat-label>
            <input formControlName="dateOfBirth" matInput readonly (focus)="picker.open()" [min]="minDate" [max]="maxDate" [matDatepicker]="picker" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker startView="multi-year" #picker></mat-datepicker>
            <mat-error>Date of Birth is required</mat-error>
        </mat-form-field>

        <div class="message is-error mb-15" *ngIf="message">{{message}}</div>

    </div>

    <div class="layout-row layout-align-space-between-center">
        <mat-spinner  [ngStyle]="{'visibility':isChecking ? 'visible' : 'hidden'}" [diameter]="24"></mat-spinner>
        <button type="submit" mat-stroked-button [ngClass]="form.invalid || isChecking ? '': ''"
            [disabled]="form.invalid || isChecking">
            Next
        </button>
    </div>

</form>

w<ng-container *ngIf="isReady; else loading">

    <ng-container *ngIf="!parent.message; else showWarning">

        <ng-container *ngIf="canRenew">

            <ng-container *ngIf="renewableProducts && renewableProducts.length > 0; else noProducts">

                <div class="message">
                    <p>{{parent.policyholder.name}} is up for renewal. Select renewal package:</p>
                </div>

                <!-- renewProductsTable -->
                <table>

                    <thead>
                        <tr>
                            <td>renewTo</td>
                            <td style="width: 100px; text-align: right;">price</td>
                        </tr>
                    </thead>

                    <tbody>
                        <tr [class.selectedProduct]="selectedProduct && selectedProduct == renewable.productTag"
                            *ngFor="let renewable of renewableProducts" (click)="selectRenewal(renewable)">
                            <td>{{renewable.productTag}}</td>
                            <td>{{renewable.totalPrice | currency: ' KES '}}</td>
                        </tr>
                    </tbody>

                </table>

            </ng-container>


        </ng-container>

        <ng-container *ngIf="!canRenew">

            <ng-container *ngIf="products && products.length > 0; else noProducts">

                <!-- orderProductsTable-->
                <div class="table-responsive">
                    <table class="table">

                        <thead>
                            <tr>
                                <td>product</td>
                                <td style="width: 100px; text-align: right;">price</td>
                            </tr>
                        </thead>

                        <tbody>
                            <tr [class.selectedProduct]="selectedProduct && selectedProduct == product.id"
                                *ngFor="let product of products" (click)="selectProduct(product)">
                                <td>
                                    {{product.name}}<br>
                                    <small>{{product.description}}</small>
                                </td>
                                <td class="text-right" style="vertical-align: top;">{{product.price | defaultCurrency:
                                    product.currency:'code':'1.0-0' }}</td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </ng-container>

        </ng-container>

        <div class="tw-flex tw-flex-row tw-justify-between tw-mt-15">

            <button type="button" mat-button class="btn" mat-dialog-close>
                cancel
            </button>

            <button type="button" mat-button class="btn" *ngIf="!isEdit"
                [ngClass]="{'tw-bg-brand-default': !!selectedProduct}" [disabled]="!selectedProduct"
                (click)="productNext()">
                next
            </button>

            <button type="button" mat-button class="btn" *ngIf="isEdit"
                [ngClass]="{'tw-bg-green-400': !!selectedProduct}" [disabled]="!selectedProduct"
                (click)="productUpdate()">
                update
            </button>

        </div>

    </ng-container>

</ng-container>

<ng-template #loading>
    <section class="tw-py-4 tw-m-4">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        <h3 style="text-align: center;margin-top: 15px">Loading</h3>
    </section>
</ng-template>

<ng-template #showWarning>

    <p>{{parent.message}}</p>

    <div>
        <button type="button" mat-button class="btn" mat-dialog-close>
            ok
        </button>
    </div>

</ng-template>

<ng-template #noProducts>

    <div class="tw-py-4">
        <mat-divider></mat-divider>
    </div>

    <p>There are none available products at this time</p>

</ng-template>
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { TokenService } from '@core/services/token.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '@environments/environment';

export interface ICommandResponse
{
    commandId: string;
    data?: any;
    executionTime: number;
    isCompleted: boolean;
    isSuccess: boolean;
    message?: string;
}

@Injectable({providedIn: 'root'})
export class CommandService
{
    constructor(
        private httpClient: HttpClient,
        private tokenService: TokenService,
        private authService: AuthService,
        private router: Router,
        private snackBar: MatSnackBar,
    ) { }

    public async publishAsync(commandName: string, command: {}): Promise<ICommandResponse>
    {
        let response: ICommandResponse | undefined;

        try
        {
            if (!this.tokenService.isAuth)
            {
                await this.authService.refreshAsync();
            }

            const appId: string = environment.appId.toString();
            const authorization: string = `Bearer ${this.tokenService.token}`;
            const headers = new HttpHeaders({
                Authorization: authorization,
                AppId: appId,
                Command: commandName,
            });

            response = await this.httpClient
                .post<ICommandResponse>(`${environment.commandUrl}`, command, { headers, withCredentials: true })
                .toPromise();

        } catch (error)
        {
            if ('status' in error && error.status === 401)
            {
                this.snackBar.open('Your session has expired. Please sign in again')
                this.router.navigate(['/auth/signin']);
                return;
            }

            this.snackBar.open(error.message || 'An error ocurred')
            throw error;
        }

        return response;
    }
}

import { Injectable } from '@angular/core';
import { CommandService, ICommandResponse } from '@core/services/command.service';
import { QueryService, IQueryResponse } from '@core/services/query.service';

export interface HospitalQueryPayload
{
    searchString?: string;
    geolocationId?: string;
}

export interface HospitalQueryResult
{
    hospitals: HospitalModel[];
}

export interface HospitalModel
{
    id: string;
    name: string;
}

@Injectable({providedIn: 'root'})
export class HospitalRepository
{
    constructor(private queryService: QueryService) { }

    public getHospitals(data: HospitalQueryPayload): Promise<IQueryResponse<HospitalQueryResult>>
    {
        return this.queryService.queryAsync('HospitalQuery', data);
    }
}

import { Component, Output, Input, EventEmitter } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { BaseComponent } from '@core/abstracts/base.component';
import { IdentityModel, IdentityRepository, SignupKenyaNoIprsPayload } from "@shared/repositories/identity.repository";
import { UserRepository, CheckIdentityModel, IdTypes, CheckIdentityStatus } from "@shared/repositories/user.repository";
import { DateService } from "@shared/services/date.service";


@Component({
    selector: 'identity-kenya-no-iprs',
    templateUrl: 'identity-kenya-noiprs.component.html',
    standalone: false
})

export class IdentityKenyaNoIprsComponent extends BaseComponent
{
    public maxDate: Date;
    public form: UntypedFormGroup;
    public message?: string = null;
    public isLoading: boolean = false;

    @Input()
    public countryId: string;


    @Output()
    public identityEvent = new EventEmitter<IdentityModel>();

    constructor(
        private dateService: DateService,
        private identityRepository: IdentityRepository,
        private userRepository: UserRepository)
    {
        super();

        this.maxDate = this.dateService.dayjs.utc().toDate();

        this.form = new UntypedFormGroup({
            firstName: new UntypedFormControl(null, [Validators.required]),
            familyName: new UntypedFormControl(null, [Validators.required]),
            gender: new UntypedFormControl(0, [Validators.required]),
            idNumber: new UntypedFormControl(null, [Validators.required]),
            dateOfBirth: new UntypedFormControl(null, [Validators.required]),
            phone: new UntypedFormControl(null, [Validators.required]),
            email: new UntypedFormControl(null, [Validators.email])
        });
    }

    public async submitAsync(ev: Event): Promise<void>
    {
        ev.preventDefault();


        if (this.form.invalid)
        {
            this.message = 'Required fields must be submitted';
            return;
        }

        const data: CheckIdentityModel =
        {
            idType: IdTypes.NationalId,
            idNumber: this.form.value.idNumber,
            includeLocalUsers: true
        }

        // check again if this user exists in database
        this.isLoading = true;
        const response = await this.userRepository.checkIdentityAsync(data)
        const idCheck = response.result;
        this.isLoading = false;
        switch (idCheck.status)
        {
            case CheckIdentityStatus.FoundLocally || CheckIdentityStatus.FoundRemotely:
                this.identityEvent.emit(
                    {
                        idNumber: idCheck.details.idNumber,
                        name: idCheck.details.name,
                        dateOfBirth: new Date(idCheck.details.dateOfBirth),
                        userId: idCheck.details.userId,
                        phone: this.form.value.phone,
                    });
                break;
            default:

                const data: SignupKenyaNoIprsPayload =
                {
                    firstName: this.form.value.firstName,
                    familyName: this.form.value.familyName,
                    gender: Number(this.form.value.gender),
                    nationalId: this.form.value.idNumber,
                    phoneNumber: this.form.value.phone,
                    dateOfBirth: this.form.value.dateOfBirth,
                    email: this.form.value.email,
                }

                this.isLoading = true;
                const response = await this.identityRepository.signupKenyaNoIprs(data);
                this.isLoading = false;
                if (response.isSuccess && response.data)
                {
                    const user = response.data.user;
                    console.log(user);
                    this.identityEvent.emit(
                        {
                            idNumber: user.nationalId,
                            phone: user.phoneNumber,
                            name: user.name,
                            dateOfBirth: user.dateOfBirth,
                            userId: user.id,
                        });
                }

                this.message = response.message;
                break;
        }
    }
}

import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { BaseComponent } from '@core/abstracts/base.component';


@Component({
    selector: 'search-user-dialog',
    templateUrl: 'search-user.dialog.html',
    standalone: false
})
export class SearchUserDialog extends BaseComponent
{

    constructor(private dialogRef: MatDialogRef<SearchUserDialog>)
    {
        super();
    }

    public selectExistingUser(user: any)
    {
        user = { ...user, userId: user.id };
        this.dialogRef.close(user);
    }

    public selectNewUser(user: any)
    {
        this.dialogRef.close(user);
    }
}

import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaymentComponentConfig } from "./payment";

@Component({
    templateUrl: './dialog.html',
    standalone: false
})
export class PaymentDialogComponent
{
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: PaymentComponentConfig) 
        { }

    public get title(): string
    {
        return this.data?.provider?.name || 'Select Payment Option';
    }
}
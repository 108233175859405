import { Injectable } from '@angular/core';
import { QueryService, IQueryResponse } from '@core/services/query.service';

export interface ReportsModel
{
    id: string;
    createdAt: Date;
    type: string;
    name: string;
    summary: string;
    access_level: number;
}

@Injectable({providedIn: 'root'})
export class ReportRepository
{
    constructor(private queryService: QueryService) { }

    public getReports(): Promise<IQueryResponse<ReportsModel[]>>
    {
        return this.queryService.queryAsync('GetReports', {});
    }
}

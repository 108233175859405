import { Injectable } from "@angular/core";
import { CommandService, ICommandResponse } from '@core/services/command.service';
import { QueryService, IQueryResponse } from '@core/services/query.service';


export interface PaymentsQueryConfig
{
    direction: 1 | 2,
    id: string,
    idType: 'c' | 'dc'
}

@Injectable({ providedIn: 'root' })
export class PaymentRepository
{
    constructor(
        private commandService: CommandService,
        private queryService: QueryService,
    ) {}

    public async getProviders(config: PaymentsQueryConfig): Promise<any[]>
    {
        const response = await this.queryService.queryAsync<any[]>('PaymentsQuery', config);
        return response.result;
    }

    public async getPaymentConfigs(data: any): Promise<any[]>
    {
        const response = await this.queryService.queryAsync<any[]>('PaymentConfigsQuery', data);
        return response.result;
    }

}

import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";


export interface Identity
{
    token: string;
    sessionId: string;
    userId: string;
    name: string;
    nationalId: string;
    countryId: string;
    isRoot: boolean;
    exp: number;
    iat: number;
    nbf: number;
    trainerPayout: number;
}

@Injectable({ providedIn: 'root' })
export class TokenService
{
    private _jwtHelperService = new JwtHelperService(null);

    constructor(private router: Router) { }

    public get token(): string | null
    {
        return this.getToken();
    }

    public get isAuth(): boolean
    {
        return typeof this.token === 'string'
            && !this._jwtHelperService.isTokenExpired(this.token, 1);
    }

    public set(token?: string | null | undefined): void
    {
        this.storeToken(token);
    }

    public getIdentity(): Identity
    {
        const t = this.token || undefined;
        return this._jwtHelperService.decodeToken(t);
    }

    public getUserId(): string | null
    {
        return this.getIdentity()?.userId || null;
    }

    public getCountryId(): string | null
    {
        return this.getIdentity()?.countryId || null;
    }

    public getIsRoot(): boolean
    {
        return this.getIdentity()?.isRoot || false;
    }

    public getSessionId(): string | null
    {
        return this.getIdentity()?.sessionId || null;
    }

    private storeToken(token: string | null | undefined) 
    {

        if (token)
        {
            localStorage.setItem('session', token);
        }
        else
        {
            this.router.navigateByUrl('/auth');

            localStorage.removeItem('session');
        }; // sign out3
    }

    private getToken(): string | undefined
    {
        return localStorage.getItem('session');
    }

}

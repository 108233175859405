import { Injectable } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class NotificationService
{
    horizontalPosition: MatSnackBarHorizontalPosition = 'center'
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';

    constructor(private _snackBar: MatSnackBar)
    {
    }


    public openSnackBar(message, toplay = false)
    {
        if (toplay)
        {
            this.playAudio();
        }

        this._snackBar.open(message, 'Dismiss', {
            duration: 5000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }

    private playAudio()
    {
        const audio = new Audio();
        audio.src = 'assets/sound/notification.mp3';
        audio.load();
        audio.play();
    }

}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA,  MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseComponent } from '@core/abstracts/base.component';

export interface ConfirmDialogData 
{
    title: string;
    message: string;
    confirm?: string;
    dismiss?: string;
    onConfirm?: string;
    onDismiss?: string;
}

@Component({
    templateUrl: './confirm-dialog.component.html',
    standalone: false
})
export class ConfirmDialogComponent extends BaseComponent
{
    constructor(
        @Inject(MAT_DIALOG_DATA) 
        public data: ConfirmDialogData,
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        private snackbar: MatSnackBar
    ) 
    {
        super();
    }

    public onConfirm(): void 
    {
        // Close the dialog, return true
        this.dialogRef.close(true);
        if(this.data.onConfirm) this.snackbar.open(this.data.onConfirm);
    }

    public onDismiss(): void {
        // Close the dialog, return false
        this.dialogRef.close(false);
        if(this.data.onDismiss) this.snackbar.open(this.data.onDismiss);
    }
}



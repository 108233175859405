import { Injectable } from '@angular/core';
import { CommandService, ICommandResponse } from '@core/services/command.service';
import { QueryService, IQueryResponse } from '@core/services/query.service';

@Injectable({providedIn: 'root'})
export class RoleRepository
{
    constructor(
        private commandService: CommandService, 
        private queryService: QueryService,
    ) {}

    public create(data: any): Promise<any>
    {
        return this.commandService.publishAsync('RoleCreate', data);
    }

    public async getRole(roleId: string, includeAccessList: boolean = false): Promise<any>
    {
        const response = await this.queryService.queryAsync("RoleQuery", { roleId, includeAccessList })
        return response.result;
    }

    public save(data: any): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('SaveRole', data);
    }

    public setRoleAccess(roleId: string, accessId: string, value: number): Promise<ICommandResponse> {

        return this.commandService.publishAsync('RoleSetAccess', { roleId, accessId, value });
    }
}

<form [formGroup]="form" novalidate (submit)="submit()">

    <div class="layout-column">

        <mat-form-field >
            <mat-label>Phone Number</mat-label>
            <input type="text" matInput formControlName="phoneNumber" />
            <mat-error>Required</mat-error>
        </mat-form-field>

        <div class="layout-row layout-align-space-between-start">
            <mat-form-field class="flex" >
                <mat-label>amount</mat-label>
                <input type="number" readonly matInput formControlName="amount" />
                <mat-error>requiredDigitsOnly</mat-error>
            </mat-form-field>

            <mat-form-field class="flex-25 flex-gt-md-15" >
                <mat-label>currency</mat-label>
                <input type="text" readonly matInput formControlName="currency" />
                <mat-error>Required</mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="tw-flex tw-flex-row tw-justify-between">

        <button type="button" mat-button mat-dialog-close="false">
            Cancel
        </button>

        <button type="submit" mat-button class="">
            Submit
        </button>

    </div>

</form>

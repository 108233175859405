import { Component, Input } from '@angular/core'
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { PaymentDialogComponent } from './dialog';
import { CommandService } from '@/core/services/command.service';
import { PaymentComponentConfig } from './payment';

@Component({
    selector: 'payment-manual',
    templateUrl: './manual.html',
    standalone: false
})
export class ManualPaymentComponent
{
    public form: FormGroup;
    @Input() config: PaymentComponentConfig;
    public isSubmitting: boolean = false;

    constructor(private commandService: CommandService,
        private dialog: MatDialogRef<PaymentDialogComponent>,)
    {

    }

    public get provider()
    {
        return this.config?.provider;
    }

    public ngOnInit()
    {
        this.form = new FormGroup({
            paymentDetails: new FormControl(undefined, [Validators.required])
        });
    }

    public async submit()
    {
        if (!this.isSubmitting) 
        {
            this.isSubmitting = true;
            const response = await this.commandService.publishAsync('BankPayment', {
                targetType: this.config.targetType,
                targetId: this.config.targetId,
                paymentMethod: 3,
                paymentDetails: this.form.value['paymentDetails'],
                currency: this.config.currency
            });

            if (response.isSuccess)
            {
                this.dialog.close(true);
            }
            else
            {
                this.isSubmitting = false;
            }
        }
    }
}
import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';


@Injectable({ providedIn: 'root' })
export class DateService
{
    private _dayjs: dayjs.Dayjs;

    constructor()
    {
        this._dayjs = dayjs.extend(utc);
    }

    public get dayjs(): dayjs.Dayjs
    {
        return this._dayjs;
    }
}

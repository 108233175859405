import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { OrderType } from './order-product.component';
import { OrderComponent } from './order.component';


@Component({
    selector: 'order-confirm',
    templateUrl: 'order-confirm.component.html',
    standalone: false
})
export class OrderConfirmComponent
{
    @Input() public parent: OrderComponent;
    public countryCode = "KE"

    public form: UntypedFormGroup;
    public isSubmitted = false;

    constructor()
    {
        this.form = new UntypedFormGroup({
            confirmed: new UntypedFormControl(false, [Validators.requiredTrue])
        });  
    }
    ngOnInit(){
        this.countryCode = this.parent.collective.countryCode;
    }
    
    public get isRenewable(): boolean 
    {
        return this.parent.product.orderType  === OrderType.Renewal;
    }

    public async previous(): Promise<void>
    {
        this.parent.previous();
    }

    public get confirmed(): boolean
    {
        return this.form.get('confirmed').value || false;
    }

    public async submitAsync(ev: Event): Promise<void>
    {
        ev.preventDefault();

        if (this.form.invalid)
        {
            return;
        }

        this.isSubmitted = true;
        
        this.parent.onConfirmSubmit();
    }
}

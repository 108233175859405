import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { CommandService } from '@core/services/command.service';
import { QueryService } from '@core/services/query.service';
import { Subject } from 'rxjs';


export interface FileItem
{
    file: File;
    progress: Subject<number>;
}

export interface FileData
{
    domainName: string;
    domainId: string;
}

export interface InitUploadList extends Array<InitUploadItem> { }
{
}

export interface InitUploadItem
{
    uploadUri: string;
    origFilename: string;
    newFilename: string;
}

@Injectable({
    providedIn: 'root'
})
export class FileUploadService
{
    constructor(
        private httpClient: HttpClient,
        private commandService: CommandService,
        private queryService: QueryService
    ) { }

    public async uploadAsync(data: FileData, items: Set<FileItem>): Promise<void>
    {
        const ar = Array.from(items);

        const filenames = ar.map(x => x.file.name);
        const list = await this.initFileUploadAsync(filenames);

        const files = [];
        list.forEach(async (x) =>
        {

            const item = ar.find(z => z.file.name === x.origFilename);
            if (!item) return;

            files.push(x);
            await this.uploadToServerAsync(item, x.uploadUri);
        });

        await this.confirmFileUpload(data, files);
    }

    private async initFileUploadAsync(filenames: string[]): Promise<InitUploadList>
    {
        const response = await this.commandService.publishAsync('UploadInit', { filenames });
        return response.data.list || [];
    }

    private async uploadToServerAsync(item: FileItem, putUrl: string): Promise<void>
    {
        const date: string = new Date().toUTCString();
        const headers = new HttpHeaders({
            'x-ms-blob-type': 'BlockBlob',
            'x-ms-blob-content-type': item.file.type,
            'x-ms-date': date,
            'x-ms-version': '2019-07-07'
        });

        const init = { headers, reportProgress: true };
        const req = new HttpRequest('PUT', putUrl, item.file, init);

        this.httpClient.request(req).subscribe(
            (event) =>
            {
                if (event.type === HttpEventType.UploadProgress)
                {
                    const progress = Math.round(100 * event.loaded / event.total);
                    item.progress.next(progress);
                }
                else if (event instanceof HttpResponse)
                {
                    item.progress.next(100);
                    item.progress.complete();
                }
            });
    }

    private async confirmFileUpload(data: FileData, files: { filename: string, origFilename: string }[]): Promise<void>
    {
        const body = {
            domainName: data.domainName,
            domainId: data.domainId,
            files
        };

        const response = await this.commandService.publishAsync('UploadConfirm', body);
    }

    public async getFiles(domainName: string, domainId: string): Promise<any[]>
    {

        const data = { domainName, domainId };
        const response = await this.queryService.queryAsync<any[]>('GetFiles', data);
        return response.result;
    }

    public async previewFile(file: any): Promise<any>
    {
        // const data = { filename };
        //return this.httpClient.post(environment.filesUrl + '/upload/delete', data).toPromise();
    }

    public async removeFile(fileId: string): Promise<boolean>
    {
        const response = await this.commandService.publishAsync('FileRemove', { fileId });
        return response.isSuccess;
    }
}

import { Injectable } from '@angular/core';
import { CommandService, ICommandResponse } from '@core/services/command.service';
import { QueryService, IQueryResponse } from '@core/services/query.service';


export interface IllnessModel
{
    id: string;
    name: string;
}

@Injectable({providedIn: 'root'})
export class IllnessRepository
{
    constructor(private queryService: QueryService) { }

    public getIllnesses(): Promise<IQueryResponse<IllnessModel[]>>
    {
        return this.queryService.queryAsync('IllnessQuery', {});
    }
}

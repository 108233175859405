import { Component, Output, Input, EventEmitter } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { BaseComponent } from '@core/abstracts/base.component';
import { IdentityModel, IdentityRepository, SignUpBurundiPayload } from "@shared/repositories/identity.repository";
import { UserRepository, CheckIdentityModel, IdTypes, CheckIdentityStatus } from "@shared/repositories/user.repository";
import { DateService } from "@shared/services/date.service";


@Component({
    selector: 'identity-burundi',
    templateUrl: 'identity-burundi.component.html',
    standalone: false
})

export class IdentityBurundiComponent extends BaseComponent
{
    public maxDate: Date;
    public form: UntypedFormGroup;
    public message?: string = null;
    public isLoading: boolean = false;

    @Input()
    public countryId: string;


    @Output()
    public identityEvent = new EventEmitter<IdentityModel>();

    constructor(
        private dateService: DateService,
        private identityRepository: IdentityRepository,
        private userRepository: UserRepository)
    {
        super();

        this.maxDate = this.dateService.dayjs.utc().toDate();

        this.form = new UntypedFormGroup({
            idNumber: new UntypedFormControl(null, [Validators.required]),
            firstName: new UntypedFormControl(null, [Validators.required]),
            familyName: new UntypedFormControl(null, [Validators.required]),
            dateOfBirth: new UntypedFormControl(null, [Validators.required]),
            gender: new UntypedFormControl(0, [Validators.required]),
            phone: new UntypedFormControl(null),
            haugeCustomerId: new UntypedFormControl(null, [Validators.required]),
        });
    }

    public async submitAsync(ev: Event): Promise<void>
    {
        ev.preventDefault();


        if (this.form.invalid)
        {
            this.message = 'Required fields must be submitted';
            return;
        }

        this.message = null;

        const data: CheckIdentityModel =
        {
            idType: IdTypes.NationalId,
            idNumber: this.form.value.idNumber,
            includeLocalUsers: true
        }

        // check if this user exists in database
        this.isLoading = true;
        const response = await this.userRepository.checkIdentityAsync(data)
        const idCheck = response.result;
        this.isLoading = false;
        switch (idCheck.status)
        {
            case CheckIdentityStatus.FoundLocally || CheckIdentityStatus.FoundRemotely:
                this.identityEvent.emit(
                    {
                        idNumber: idCheck.details.idNumber,
                        name: idCheck.details.name,
                        dateOfBirth: new Date(idCheck.details.dateOfBirth),
                        userId: idCheck.details.userId,
                        phone: this.form.value.phone,
                    });
                break;
            case CheckIdentityStatus.NotFound:

                const data: SignUpBurundiPayload =
                {
                    firstName: this.form.value.firstName,
                    familyName: this.form.value.familyName,
                    gender: Number(this.form.value.gender),
                    nationalId: this.form.value.idNumber,
                    phoneNumber: this.form.value.phone,
                    haugeCustomerId: this.form.value.haugeCustomerId,
                    dateOfBirth: this.form.value.dateOfBirth,
                }

                this.isLoading = true;
                const response = await this.identityRepository.signupBurundi(data);
                this.isLoading = false;
                if (response.isSuccess && response.data.userId)
                {
                    this.identityEvent.emit(
                        {
                            idNumber: data.nationalId,
                            name: data.firstName + ' ' + data.familyName,
                            dateOfBirth: data.dateOfBirth,
                            userId: response.data.userId,
                            phone: data.phoneNumber,
                        });
                }

                this.message = response.message;
                break;
        }
    }
}

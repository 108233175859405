import { Injectable } from '@angular/core';
import { CommandService, ICommandResponse } from '@core/services/command.service';
import { QueryService, IQueryResponse } from '@core/services/query.service';


export interface MessagesQueryResponse
{
    messages: MessageEntity[];
    totalCount: number;
}

export interface MessageEntity
{
    id: string;
    body: string;
    createdAt: string;
    receiverNames: string;
    senderName: string;
    sentAt: string;
    subject: string;
    totalCount: number
}

export enum MessageReceiverType
{
    User = 1,
    Collective = 2,
    Role = 3,
    Organization = 4,
    Administrators = 5,
    Callcenter = 6,
    CollectiveTrainer = 7,
    Phone = 8,
    Internal = 9,
    Customers = 10,
    Trainers = 11,
    Members = 12
}

export enum MessageDeliveryOption
{
    None = 0,
    Email = 1,
    Sms = 2
}

export interface MessageReceiver
{

}

export interface MessageCreate
{
    senderId?: string;
    receivers: string;
    subject: string;
    body: string;
    deliveryOption: MessageDeliveryOption;
    messageCode: string;
}

export interface MessagesQueryModel
{
    userId: string;
    skip?: number;
    take?: number;
}

@Injectable({ providedIn: 'root' })
export class MessageRepository
{
    constructor(
        private queryService: QueryService,
        private commandService: CommandService
    ) { }

    public getMessages(data: MessagesQueryModel): Promise<IQueryResponse<MessagesQueryResponse>>
    {
        return this.queryService.queryAsync('MessagesQuery', data);
    }

    public create(data: MessageCreate): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('MessageCreate', data);
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '@core/abstracts/base.component';
import { GeolocationRepository, GeolocationModel, GeolocationQuery } from '@shared/repositories/geolocation.repository';
import { debounceTime, switchMap } from 'rxjs/operators';

@Component({
    selector: 'geolocation',
    templateUrl: './geolocation.component.html',
    standalone: false
})
export class GeolocationComponent extends BaseComponent
{
  public geolocationControl = new FormControl();
  public geolocations: GeolocationModel[];

  @Input()
  public countryId?: string;

  @Input()
  public type?: string = 'Geolocation';

  @Output()
  public geolocationEvent = new EventEmitter<GeolocationModel>();

  constructor(private geolocationRepository: GeolocationRepository) 
  {
    super();
    this.geolocationControl.valueChanges.pipe(
      debounceTime(1000),
      switchMap(value => this.getGeolocation(value))
    ).subscribe(value =>
    {
      if (value != null)
      {
        this.geolocations = value;
      }
    });
  }

  public async ngOnInit(): Promise<void>
  {
    await this.loadAsync();
  }

  public async loadAsync(): Promise<void>
  {
    this.geolocations = await this.getGeolocation();
  }

  private async getGeolocation(searchString?: string): Promise<GeolocationModel[]>
  {
    const payload: GeolocationQuery =
    {
      countryId: this.countryId,
      searchString: searchString
    }
    const response = await this.geolocationRepository.getGeolocations(payload);
    if (response.isSuccess && response.result)
    {
      return response.result;
    }
    return [];
  }

  public selectGeolocation(geolocation: GeolocationModel): void
  {
    this.geolocationEvent.emit(geolocation);
  }
}

import { Injectable } from '@angular/core';
import { TokenService } from '@core/services/token.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({ providedIn: 'root' })
export class ExcelService
{

    constructor(private tokenService: TokenService) { }

    public exportTableAsExcelFile(table: HTMLTableElement, excelFileName: string): void
    {

        const myworksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);
        // footer
        const footer = this.generateFooter();
        XLSX.utils.sheet_add_aoa(myworksheet, [[footer]], { origin: -1 });
        const myworkbook: XLSX.WorkBook = { Sheets: { 'data': myworksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    public exportJsonAsExcelFile(json: any[], excelFileName: string): void
    {

        const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const myworkbook: XLSX.WorkBook = { Sheets: { 'data': myworksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    private saveAsExcelFile(buffer: any, fileName: string): void
    {
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });

        const currentDate = new Date().toISOString();
        FileSaver.saveAs(data, `${fileName}_${currentDate}${EXCEL_EXTENSION}`);
    }

    private generateFooter(): String
    {
        const date = new Date();

        // Get the individual components of the date
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // Month is zero-indexed, so add 1
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        // Format the date as a human-readable string
        const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day} ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;

        const username = this.tokenService.getIdentity().name
        return `generated by ${username} on ${formattedDate}`;

    }
}

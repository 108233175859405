import { Injectable } from '@angular/core';
import { CommandService, ICommandResponse } from '@core/services/command.service';
import { QueryService, IQueryResponse } from '@core/services/query.service';


export interface GetFilesPayload
{ domainName: string, domainId: string }


@Injectable({ providedIn: 'root' })
export class FileRepository
{
    constructor(private queryService: QueryService) { }

    public getFileSasUri(filename): Promise<IQueryResponse<any>>
    {
        return this.queryService.queryAsync<string[]>('GetFileSasUri', { filenames: [filename] });
    }

    public getFilesAsync(data: GetFilesPayload): Promise<IQueryResponse<any[]>>
    {
        return this.queryService.queryAsync<any[]>('GetFiles', data);
    }
}

import { Injectable } from '@angular/core';
import { CommandService, ICommandResponse } from '@core/services/command.service';
import { IQueryResponse, QueryService } from '@core/services/query.service';


export interface CompanyModel
{ 
    id: string;
    organizationId: string;
    createdAt: Date;
    name: string;
    email: string;
    phoneNumber: string;
    address: string;
}

export interface CompaniesQueryResult 
{
    companies: CompanyModel[];
    totalCount: number;
}

export interface CompanyQueryResult
{
    company: CompanyModel;
}

export interface CompanyCreateModel
{ 
    name: string;
    organizationId: string;
    email: string;
    phoneNumber: string;
    address: string;
}

@Injectable({providedIn: 'root'})
export class CompanyRepository
{
    constructor(
        private commandService: CommandService,
        private queryService: QueryService)
    {
    }


    public async getCompanies(data: any): Promise<IQueryResponse<CompaniesQueryResult>>
    {
        return await this.queryService.queryAsync("CompaniesQuery", data);
    }

    public async getCompany(companyId: string): Promise<IQueryResponse<CompanyQueryResult>> 
    {
        return await this.queryService.queryAsync("CompanyQuery", { companyId });
    }

    public create(data: CompanyCreateModel): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('CompanyCreate', data);
    }

    public update(data: { name: string }): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('CompanyUpdate', data);
    }

    public user(data: any): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('CompanySetUser', data);
    }


    public removeUser(data: any): Promise<ICommandResponse>
    {        
        return this.commandService.publishAsync('CompanyRemoveUser', data);
    }

    
}

<h3 mat-dialog-title class="tw-uppercase">{{title}}</h3>

<div mat-dialog-content>

    <ng-container *ngIf="!identity">
        <identity [countryId]="countryId" (identityEvent)="handleIdentity($event)"></identity>
    </ng-container>

    <ng-container *ngIf="identity">

        <dl>
            <dt>Identifaction Number</dt>
            <dd>{{identity.idNumber}}</dd>

            <dt>Name</dt>
            <dd>{{identity.name}}</dd>

            <dt>Date of Birth</dt>
            <dd>{{identity.dateOfBirth | date}}</dd>
        </dl>

        <div class="message is-error" *ngIf="message">{{message}}</div>

        <div class="layout-row layout-align-space-between-start">

            <button type="button" mat-button mat-dialog-close class="btn">
                Cancel
            </button>

            <button type="button" mat-button class="" (click)="submitAsync($event)">
                Confirm
            </button>

        </div>

    </ng-container>

</div>
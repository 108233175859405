<ng-container *ngIf="isLoading; else loadedContent">
    <ddf-loader></ddf-loader>
  </ng-container>

  <ng-template #loadedContent>
    <ng-container *ngIf="hasData; else emptyState">
      <ng-content></ng-content>
    </ng-container>
  </ng-template>

  <ng-container *ngIf="showEmptyState">
    <ddf-empty-list [text]="stateMessage" [showCard]="showCard"></ddf-empty-list>
  </ng-container>
import { NotificationService } from "@/core/providers/notification.service";
import { Component, Input } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { PaymentDialogComponent } from "./dialog";
import { PaymentRepository, PaymentsQueryConfig } from "@/shared/repositories/payment.repository";

export interface PaymentComponentConfig
{
    queryConfig: PaymentsQueryConfig,
    targetType: number,
    targetId: string,
    amount: number,
    currency: string,
    receiver: {
        phoneNumber?: string,
        name?: string
    },
    provider?: any
}

@Component({
    selector: 'payment',
    templateUrl: './payment.html',
    standalone: false
})
export class PaymentComponent
{
    public providers: any[];

    @Input() 
    config: PaymentComponentConfig;

    constructor(
        private notificationService: NotificationService,
        private dialog: MatDialogRef<PaymentDialogComponent>,
        private paymentRepository: PaymentRepository
    ) { }

    public get isLoaded()
    {
        return typeof this.providers !== 'undefined';
    }

    public get selected(): any
    {
        return this.config.provider;
    }

    async ngOnInit(): Promise<void>
    {
        this.providers = await this.paymentRepository.getProviders(this.config.queryConfig);
        
        if (typeof this.providers === 'undefined')
        {
            this.notificationService.openSnackBar('No payment providers found. Contact support');
            this.dialog.close(false);
        }

        if (this.providers.length === 1)
        {
            this.select(this.providers[0]);
        }
    }

    public select(provider: any): void
    {
        this.config.provider = provider;
    }
}
import { Component, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator'; import { BaseComponent } from '@core/abstracts/base.component';
import { User, UserRepository, UserSearchModel, UserSearchQueryPayload } from '@shared/repositories/user.repository';
import { Subject } from 'rxjs';


@Component({
    selector: 'search-user',
    templateUrl: './search-user.component.html',
    standalone: false
})
export class SearchUserComponent extends BaseComponent
{

    @Output()
    public onSelected = new Subject();
    public searchInput = new UntypedFormControl(null, []);
    public users: UserSearchModel[];
    public isLoading: boolean = false;

    constructor(private userRepository: UserRepository)
    {
        super();
    }

    public async ngOnInit(): Promise<void>
    {
        this.searchInput.valueChanges.pipe(

            debounceTime(1000),
            distinctUntilChanged(),
            switchMap(_ => this.loadAsync()),
        )
            .subscribe();
    }

    public async loadAsync(): Promise<void>
    {

        this.isLoading = true;

        const data: UserSearchQueryPayload =
        {
            searchString: this.searchInput.value,
        };

        const response = await this.userRepository.searchUsers(data);
        if (response && response.isSuccess) 
        {
            this.users = response.result.users;
        }
        this.isLoading = false;
    }

    public select(user: User): void
    {
        if (!user) return;
        this.onSelected.next(user);
    }
}

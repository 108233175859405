import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenService } from '@core/services/token.service';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class AccessService
{
    private _accessList: { [key: string]: number } | null = null;

    constructor(
        private tokenService: TokenService,
        private httpClient: HttpClient,
    ) { }

    public async loadAccessAsync(): Promise<void>
    {
        const token = this.tokenService.token;
        const url = `${environment.authUrl}/access`;

        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`
        });

        const body = {};
        this._accessList = {};

        const response = await this.httpClient.post<{ accessList: { [key: string]: number } }>(url, body, { headers }).toPromise();
        if (response && response.accessList)
        {
            const keys = Object.keys(response.accessList);
            for (let i of keys)
            {
                const key = i.toUpperCase();
                const value = response.accessList[i];
                this._accessList[key] = value;
            }
        }
    }

    public hasAccess(accessKey: string, minAccessValue: number = 1): boolean
    {
        if (!this._accessList) return false;
        const item = this._accessList[accessKey.toUpperCase()];

        return item >= minAccessValue;
    }
}


<form [formGroup]="form" novalidate (submit)="submit()" *ngIf="!isLoading; else loader">

    <div class="layout-column">

        <mat-form-field >
            <mat-label>Phone Number</mat-label>
            <input type="text" matInput formControlName="phoneNumber" />
            <mat-error>Required</mat-error>
        </mat-form-field>
        
        <mat-form-field  class="flex">
            <mat-label>paymentMethod</mat-label>
            <mat-select formControlName="paymentMethod">
                <mat-option>select paymentMethod</mat-option>
                <mat-option [value]="item.key" *ngFor="let item of paymentMethods">
                    {{item.val}}
                </mat-option>
            </mat-select>
            <mat-error>paymentMethod isRequired</mat-error>
        </mat-form-field>
        
    </div>

    <div class="tw-pb-4" *ngIf="fixed">

        <table class="table">
            <thead>
                <tr>
                    <td style="width: 50px;">#</td>
                    <td>Date</td>
                    <td style="width: 140px;">Amount</td>
                </tr>
            </thead>
            <tfoot>
                <tr>
                    <td colspan="2">Total</td>
                    <td>{{config.amount | defaultCurrency:this.config.currency}}</td>
                </tr>
            </tfoot>
            <tbody>
                <tr *ngFor="let p of generateInstalmentPlan(); let i = index">
                    <td>{{i+1}}</td>
                    <td>{{p.date}}</td>
                    <td>{{p.amount | defaultCurrency:this.config.currency}}</td>
                </tr>
            </tbody>
        </table>

        <p class="message is-info">
            You will be notified by SMS when the due date of each instalment is approaching. No refund
        </p>

    </div>

    <div class="tw-pb-4" *ngIf="flexi">

        <table class="table">
            <thead>
                <tr>
                    <td>Description</td>
                    <td style="width: 140px;">Amount</td>
                </tr>
            </thead>
            <tfoot>
                <tr>
                    <td>Total</td>
                    <td>{{config.amount | defaultCurrency:this.config.currency}}</td>
                </tr>
            </tfoot>
            <tbody>
                <tr>
                    <td>Initial Payment (minimum)</td>
                    <td>{{(getInitialPayment()) | defaultCurrency:this.config.currency}}</td>
                </tr>
                <tr>
                    <td>Remaining payment *</td>
                    <td>{{(config.amount - (getInitialPayment())) | defaultCurrency:this.config.currency}}</td>
                </tr>
            </tbody>
        </table>

        <p class="message is-info">* You decide when and how much to pay per instalment. <br>The total must be paid within 3 months. No refund</p>

    </div>
    
    <div class="tw-flex tw-flex-row tw-justify-between">

        <button type="button" mat-button mat-dialog-close="false">
            Cancel
        </button>

        <button type="submit" mat-button class="">
            Submit
        </button>

    </div>

</form>

<ng-template #loader>
    <ddf-loader></ddf-loader>
</ng-template>